<template>
  <v-app>
    <section class="login-section">
      <div class="login-blk">
        <div class="screen-lft">
          <div class="logo">
            <a href="#">
              <img src="@/assets/images/login.jpg" alt="" />
            </a>
          </div>
        </div>

        <div class="screen-rgt">
          <div class="screen-outer">
            <v-row align="center" justify="center" class="pb-6">
              <v-col cols="auto">
                <v-img
                  width="180"
                  :src="require('@/assets/images/sk-logomark.png')"
                />
              </v-col>
            </v-row>

            <div class="screen-title">
              <h2>総合研装株式会社 管理サイト</h2>
              <p class="text-center red--text pt-4" v-if="error">
                {{ error }}
              </p>
            </div>
            <div class="screen-form">
              <v-form v-model="isValid">
                <v-text-field
                  v-model="email"
                  :rules="rules.email"
                  name="email"
                  placeholder="メールアドレス"
                  filled
                  rounded
                  dense
                  class="input-text"
                  hide-details="auto"
                ></v-text-field>

                <div class="marin-bottom-field"></div>

                <v-text-field
                  v-model="password"
                  :rules="rules.password"
                  name="password"
                  placeholder="パスワード"
                  filled
                  rounded
                  dense
                  class="input-text"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  hide-details="auto"
                ></v-text-field>

                <div class="marin-bottom-field"></div>

                <div class="remember-blk">
                  <div class="custom-cr">
                    <input
                      id="check1"
                      type="checkbox"
                      name="check"
                      value="check1"
                    />
                    <label for="check1">ログイン情報を保存する</label>
                  </div>
                </div>

                <v-btn
                  block
                  class="text-capitalize white--text btn-submit"
                  large
                  color="primary"
                  @click="login"
                  :loading="isLoading"
                  >ログイン</v-btn
                >
                <div class="marin-bottom-field"></div>
                <div class="forget-blk">
                  <span class="forgot-text"
                    ><router-link :to="{ name: 'ForgotPassword' }"
                      >パスワードを忘れた？</router-link
                    ></span
                  >
                </div>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="copyright-blk">
      <p>
        ©️ {{ new Date().getFullYear() }} 総合研装株式会社, All Rights Reserved.
      </p>
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      showPassword: false,
      email: '',
      password: '',
      isValid: true,
      isLoading: false,
      error: null,
      rules: {
        email: [
          v => !!v || 'メールアドレスを入力してください。',
          v =>
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            'メールアドレスの形式は正しくありません。正しく入力してください。 '
        ],
        password: [
          v => !!v || 'パスワードを入力してください。',
          v =>
            (v && v.length >= 6) ||
            'パスワードを6文字以上の長さで入力してください。'
          // v => /(?=.*[A-Z])/.test(v) || 'Must have one uppercase character',
          // v => /(?=.*\d)/.test(v) || 'Must have one number',
          // v => /([!@$%])/.test(v) || 'Must have one special character [!@#$%]'
        ]
      }
    }
  },

  methods: {
    login() {
      if (!this.isValid) return
      // this.$router.push({
      //   name: 'ScheduleMonthly'
      // })

      // return;
      this.isLoading = true
      const payload = {
        email: this.email,
        password: this.password
      }
      this.$store
        .dispatch('AUTH_REQUEST', payload)
        .then(
          response => {
            this.isLoading = false
            if (response.data.status) this.$router.push('/schedule/task-view')
          },
          error => {
            throw error
          }
        )
        .catch(error => {
          this.isLoading = false

          if (error)
            if (error.response.data.errors)
              this.error =
                'ログインできません。アカウント情報をご確認ください。'
        })
    }
  }
}
</script>

<style scoped src="./Auth.scss" lang="scss"></style>

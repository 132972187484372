var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('section', {
    staticClass: "login-section"
  }, [_c('div', {
    staticClass: "login-blk"
  }, [_c('div', {
    staticClass: "screen-lft"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/login.jpg"),
      "alt": ""
    }
  })])])]), _c('div', {
    staticClass: "screen-rgt"
  }, [_c('div', {
    staticClass: "screen-outer"
  }, [_c('v-row', {
    staticClass: "pb-6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "width": "180",
      "src": require('@/assets/images/sk-logomark.png')
    }
  })], 1)], 1), _c('div', {
    staticClass: "screen-title"
  }, [_c('h2', [_vm._v("総合研装株式会社 管理サイト")]), _vm.error ? _c('p', {
    staticClass: "text-center red--text pt-4"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "screen-form"
  }, [_c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-text-field', {
    staticClass: "input-text",
    attrs: {
      "rules": _vm.rules.email,
      "name": "email",
      "placeholder": "メールアドレス",
      "filled": "",
      "rounded": "",
      "dense": "",
      "hide-details": "auto"
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('div', {
    staticClass: "marin-bottom-field"
  }), _c('v-text-field', {
    staticClass: "input-text",
    attrs: {
      "rules": _vm.rules.password,
      "name": "password",
      "placeholder": "パスワード",
      "filled": "",
      "rounded": "",
      "dense": "",
      "type": _vm.showPassword ? 'text' : 'password',
      "append-icon": _vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',
      "hide-details": "auto"
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.showPassword = !_vm.showPassword;
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('div', {
    staticClass: "marin-bottom-field"
  }), _c('div', {
    staticClass: "remember-blk"
  }, [_c('div', {
    staticClass: "custom-cr"
  }, [_c('input', {
    attrs: {
      "id": "check1",
      "type": "checkbox",
      "name": "check",
      "value": "check1"
    }
  }), _c('label', {
    attrs: {
      "for": "check1"
    }
  }, [_vm._v("ログイン情報を保存する")])])]), _c('v-btn', {
    staticClass: "text-capitalize white--text btn-submit",
    attrs: {
      "block": "",
      "large": "",
      "color": "primary",
      "loading": _vm.isLoading
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v("ログイン")]), _c('div', {
    staticClass: "marin-bottom-field"
  }), _c('div', {
    staticClass: "forget-blk"
  }, [_c('span', {
    staticClass: "forgot-text"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'ForgotPassword'
      }
    }
  }, [_vm._v("パスワードを忘れた？")])], 1)])], 1)], 1)], 1)])])]), _c('div', {
    staticClass: "copyright-blk"
  }, [_c('p', [_vm._v(" ©️ " + _vm._s(new Date().getFullYear()) + " 総合研装株式会社, All Rights Reserved. ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }